.App {
  text-align: center;
}

.App-header {
  background-color: #32353d;
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: Arial, serif;
  color: white;
  border: 1px solid black;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  flex-direction: column;

  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.icon-button {
  cursor: pointer;
}

.function-panel {
  color: #cbcbcb;
  text-align: left;
  font-size: medium;
  border: 1px solid gray;
  border-radius: 15px 30px;
  padding: 2%;
  margin: 1%;
  overflow-x:auto;
}
.function-panel h1{
  margin: 0px;
  border-bottom: #69769e solid;
}


