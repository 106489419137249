.users-panel {
  overflow-x:auto;
}

.users-table{
  border: 1px solid gray;
  text-align: left;
  border-spacing: 0;
  margin: 1%;
  border-collapse: collapse;
}

.users-table tr{
  margin: 0;
}

.users-table th {
  padding: 12px 8px;
  text-align: left;
  background-color: #3b435a;
  color: white;
}

.users-table tr:nth-child(odd){background-color: #2a3349;}

.users-table tr:nth-child(even){background-color: #272e3b;}

.users-table tr:hover {background-color: #535353;}

.users-table td{
  border: 1px solid #3b435a;
  margin: 0;
  padding: 8px;
}
