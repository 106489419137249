.status {
  color: #cbcbcb;
  text-align: left;
  font-size: medium;
  border: 1px solid gray;
  border-radius: 15px 30px;
  padding: 20px;
  max-height: 10vh;
  display: block;
}

.status-icon, .status-message {
  display: inline-block;
}
.status-icon {
  width: 40px;
}

.App-logo, .tick {
  height: 20px;
  pointer-events: none;
  padding: 4px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
