.user-form {
  color: #cbcbcb;
  text-align: left;
  font-size: medium;
  border: 1px solid gray;
  border-radius: 15px 30px;
  padding: 20px;
}

.user-form input, button {
  padding:5px;
  border-radius:5px;
}

.user-form input {
  background-color: #cdcdcd;
}
